import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import { SUPPORT_PHONE } from '@pickles/shared/utils/config';

import { ContentContainer, ImageWrapper } from './EInvoicingConfirmationModal.style';
import { MainTitle } from '../SigninContent/style';
import { BaseModal } from '../Shared/Modal';
import { SmallText } from './FourthStep.style';
import { BackBtn, HistoryButtons } from './SecondStep.style';
import Button from '../Shared/Button/Button';
import { OutbidIcon } from '../Shared/Icons';

interface EInvoicingConfirmationModalProps {
  onClose: () => void;
  onConfirm: () => void;
  visible: boolean;
}

const EInvoicingConfirmationModal: FC<EInvoicingConfirmationModalProps> = ({
  onClose,
  onConfirm,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpened={visible}
      onClickOutside={onClose}
      id="e-invoicing-confirmation-modal"
    >
      <ContentContainer>
        <ImageWrapper>
          <OutbidIcon width={24} height={24} />
        </ImageWrapper>
        <MainTitle
          mobileMarginTop={24}
          marginTop={24}
        >
          {t('titles:important_notice')}
        </MainTitle>
        <SmallText
          style={{
            marginTop: '32px',
            marginBottom: '0px',
          }}
        >
          {t('infos:tax_info_not_updatable')}
        </SmallText>
        <SmallText
          style={{
            marginTop: '32px',
          }}
        >{`${t('infos:ensure_tax_info_accurate')} ${t('infos:contact_pickles_support', { phone: SUPPORT_PHONE })}`}</SmallText>
        <HistoryButtons
          style={{
            fontSize: '15px',
          }}
        >
          <Button
            text={t('buttons:confirm_submit')}
            height={'52'}
            marginBottom={'20'}
            fontSize={'15'}
            id={'next'}
            type={'button'}
            onBtnClick={onConfirm}
          />
          <BackBtn id={'back'} onClick={onClose}>
            {t('buttons:back_review')}
          </BackBtn>
        </HistoryButtons>
      </ContentContainer>
    </BaseModal>
  )
};

export default EInvoicingConfirmationModal;