import { ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';

import Button from '../Shared/Button/Button';
import Checkbox from '../Shared/Checkbox/Checkbox';

import { MainTitle } from '../SigninContent/style';
import { StepWrapper } from './FirstStep.style';
import { BackBtn, HistoryButtons } from './SecondStep.style';
import { SubText } from './FourthStep.style';
import {
  Wrapper,
  SmallText,
  CheckboxWrapper,
  CheckBoxText,
  RegularTextWeight,
} from './FifthStep.style';

interface IFifthStep {
  usedCarAuction: boolean;
  usedMotorAuction: boolean;
  salvageCarAuction: boolean;
  industrialAuctionList: boolean;
  agreeToTermsAndConditions: boolean;
  handleChange: (event: ChangeEvent<any> | any) => void;
  nextStepHandler: () => void;
  previousStepHandler: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

const FifthStep = ({
  usedCarAuction,
  usedMotorAuction,
  salvageCarAuction,
  industrialAuctionList,
  agreeToTermsAndConditions,
  nextStepHandler,
  previousStepHandler,
  handleSubmit,
  handleChange,
}: IFifthStep) => {
  const { t } = useTranslation();
  return (
    <StepWrapper>
      <MainTitle
        mobileTextAlign={'left'}
        style={{
          marginBottom: '32px',
        }}
      >
        {t('labels:last_step_header')}
      </MainTitle>
      <SubText
        mobileTextAlign={'left'}
        style={{
          marginBottom: '4px',
        }}
      >
        {t('labels:last_step_title')}
      </SubText>
      <SmallText mobileTextAlign={'left'}>{t('labels:last_step_desc')}</SmallText>
      <Wrapper>
        <CheckboxWrapper>
          <Checkbox
            id="usedCarAuction"
            name="usedCarAuction"
            isChecked={usedCarAuction}
            handleChecked={handleChange}
          />
          <CheckBoxText>
            <RegularTextWeight>{t('labels:sign_me_weekly')}</RegularTextWeight>
            {` ${t('labels:car_auction')}`}
          </CheckBoxText>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            id="usedMotorAuction"
            name="usedMotorAuction"
            isChecked={usedMotorAuction}
            handleChecked={handleChange}
          />
          <CheckBoxText>
            <RegularTextWeight>{t('labels:sign_me_weekly')}</RegularTextWeight>
            {` ${t('labels:used_motor_auction_list')}`}
          </CheckBoxText>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            id="salvageCarAuction"
            name="salvageCarAuction"
            isChecked={salvageCarAuction}
            handleChecked={handleChange}
          />
          <CheckBoxText>
            <RegularTextWeight>{t('labels:sign_me_weekly')}</RegularTextWeight>
            {` ${t('labels:salvage_auction_list')}`}
          </CheckBoxText>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            id="industrialAuctionList"
            name="industrialAuctionList"
            isChecked={industrialAuctionList}
            handleChecked={handleChange}
          />
          <CheckBoxText>
            <RegularTextWeight>{t('labels:sign_me')}</RegularTextWeight>
            {` ${t('labels:industrial_auction_list')}`}
          </CheckBoxText>
        </CheckboxWrapper>
      </Wrapper>
      <CheckboxWrapper>
        <Checkbox
          id="agreeToTermsAndConditions"
          name="agreeToTermsAndConditions"
          isChecked={agreeToTermsAndConditions}
          handleChecked={handleChange}
        />
        <CheckBoxText>
          <RegularTextWeight>{t('labels:agree')}</RegularTextWeight>
          <Link href="/app-terms" passHref>
            <a target="_blank" id="terms_and_conditions">
              {t('labels:drawer_terms_and_conditions')}
            </a>
          </Link>
        </CheckBoxText>
      </CheckboxWrapper>
      <HistoryButtons>
        <Button
          text={t('buttons:create_account')}
          height="52"
          hasArrow={true}
          marginBottom="20"
          id="create_account"
          type="button"
          disabled={!agreeToTermsAndConditions}
          onBtnClick={() => {
            handleSubmit();
            nextStepHandler();
          }}
        />

        <BackBtn id="back" onClick={previousStepHandler}>
          {t('buttons:back')}
        </BackBtn>
      </HistoryButtons>
    </StepWrapper>
  );
};

export default FifthStep;
