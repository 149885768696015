import styled from 'styled-components';

export const ContentContainer = styled.div`
  max-width: 370px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;