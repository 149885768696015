import { object, string } from 'yup';

import { USER_ACCOUNT_TYPE } from '@pickles/shared/models/user.types';

import { i18n } from '../../pages/_app';

export const EInvoicingSchema = object().shape({
  accType: string()
    .oneOf([USER_ACCOUNT_TYPE.INDIVIDUAL, USER_ACCOUNT_TYPE.BUSINESS])
    .required(i18n.t('errors:required')),
  nationality: string()
    .when('accType', {
      is: USER_ACCOUNT_TYPE.INDIVIDUAL,
      then: (schema) => schema.required(i18n.t('errors:required')).nullable(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  incorpCountry: string()
    .when('accType', {
      is: USER_ACCOUNT_TYPE.BUSINESS,
      then: (schema) => schema.required(i18n.t('errors:required')).nullable(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  tinId: string()
    .required(i18n.t('errors:required'))
    .nullable(),
  sstId: string()
    .when('accType', {
      is: USER_ACCOUNT_TYPE.BUSINESS,
      then: (schema) => schema.required(i18n.t('errors:required')).nullable(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  brnId: string()
    .when('accType', {
      is: USER_ACCOUNT_TYPE.BUSINESS,
      then: (schema) => schema.required(i18n.t('errors:required')).nullable(),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .when('incorpCountry', {
      is: 'MYS',
      then: (schema) => schema.matches(/^[0-9]{12}$/, i18n.t('errors:brn_id_my_invalid')),
      otherwise: (schema) => schema,
    }),
});