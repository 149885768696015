import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserAccountType } from '@pickles/shared/models/user.types';
import { ISignUp } from '@pickles/shared/utils/types';

import { IFormikErrors } from '../../util/types';

import { MainTitle } from '../SigninContent/style';
import { StepWrapper } from './FirstStep.style';
import { BackBtn, HistoryButtons } from './SecondStep.style';
import { SmallText, SubText } from './FourthStep.style';
import { EInvoicingForm } from '../EInvoicingForm';
import Button from '../Shared/Button/Button';
import EInvoicingConfirmationModal from './EInvoicingConfirmationModal';

interface IThirdStep extends IFormikErrors<ISignUp> {
  accType: UserAccountType;
  nationality: string | null;
  incorpCountry: string | null;
  tinId: string | null;
  sstId: string | null;
  brnId: string | null;
  handleChange: (event: ChangeEvent) => void;
  validateStepHandler: () => void;
  nextStepHandler: () => void;
  previousStepHandler: () => void;
  hasErrors: boolean;
}

const ThirdStep: FC<IThirdStep> = ({
  accType,
  nationality,
  incorpCountry,
  tinId,
  sstId,
  brnId,
  handleChange,
  errors,
  hasErrors,
  validateStepHandler,
  nextStepHandler,
  previousStepHandler,
}) => {
  const { t } = useTranslation();

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const handleNextPress = useCallback(() => {
    validateStepHandler();

    if (!hasErrors) {
      setConfirmationModalVisible(true);
    }
  }, [hasErrors, validateStepHandler]);

  const handleConfirmEInvoicingRegister = useCallback(() => {
    setConfirmationModalVisible(false);
    nextStepHandler();
  }, [nextStepHandler]);

  return (
    <>
      <StepWrapper>
        <MainTitle mobileMarginTop={44} mobileTextAlign={'left'}>
          {t('titles:e_invoicing_details')}
        </MainTitle>
        <SubText
          mobileTextAlign={'left'}
          style={{
            marginTop: '32px',
          }}
        >
          {t('titles:submit_tax_info')}
        </SubText>
        <SmallText mobileTextAlign={'left'}>{t('infos:submit_tax_info')}</SmallText>
        <EInvoicingForm
          accType={accType}
          nationality={nationality}
          incorpCountry={incorpCountry}
          tinId={tinId}
          sstId={sstId}
          brnId={brnId}
          handleChange={handleChange}
          errors={errors}
        />
        <SmallText
          mobileTextAlign={'left'}
          style={{
            marginTop: '32px',
          }}
        >
          {t('infos:ensure_tax_info_accurate_to_irb')}
        </SmallText>
        <HistoryButtons>
          <Button
            hasArrow
            text={t('buttons:next')}
            height={'52'}
            marginBottom={'20'}
            id={'next'}
            type={'button'}
            onBtnClick={handleNextPress}
          />
          <BackBtn id={'back'} onClick={previousStepHandler}>
            {t('buttons:back')}
          </BackBtn>
        </HistoryButtons>
      </StepWrapper>
      <EInvoicingConfirmationModal
        onClose={() => setConfirmationModalVisible(false)}
        onConfirm={handleConfirmEInvoicingRegister}
        visible={confirmationModalVisible}
      />
    </>
  );
};

export default ThirdStep;