import styled from 'styled-components';

import colors from '@pickles/shared/utils/colors';
import fonts from '@pickles/shared/utils/fonts';

export const Label = styled.div`
  font-family: ${fonts.BC_MEDIUM_FONT};
  font-weight: 400;
  font-size: 15px;
  color: ${colors.rhino_black};
  margin-top: 32px;
`;

interface ISelect {
  isSelected?: boolean;
  error?: boolean;
}

export const Select = styled.select<ISelect>`
  width: 100%;
  height: 44px;
  margin-top: 8px;
  padding-left: 22px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${({ error }) => error ? colors.cerise_red : colors.mischka};
  font-family: ${({ isSelected }) => isSelected ? fonts.BC_MEDIUM_FONT : 'BasierCircle'};
  font-weight: 400;
  font-size: 16px;
  color: ${({ isSelected }) => isSelected ? colors.rhino_black : colors.charcoal_grey_06};;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`;

interface IRadioButton {
  isActive: boolean;
}

export const RadioButton = styled.div<IRadioButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  padding: 16px 0px;
  margin: 0;
  border-style: solid;
  border-radius: 8px;
  border-width: ${({ isActive }) => isActive ? '2px' : '1px'};
  border-color: ${({ isActive }) => isActive ? colors.blumine_main_blue : colors.mischka};
  color: ${colors.rhino_black};
`;

export const RadioInput = styled.input`
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  accent-color: ${colors.blumine_main_blue};
`;

export const RadioLabel = styled.label`
  margin-left: 8px;
`;

interface IFieldHint {
  error?: boolean;
}

export const FieldHint = styled.div<IFieldHint>`
  font-family: BasierCircle;
  font-weight: 400;
  font-size: 13px;
  color: ${({ error }) => error ? colors.cerise_red : colors.san_juan_grey_08};
  margin-top: 6px;
  text-align: left;
`;