import {
  ChangeEvent,
  FC,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { USER_ACCOUNT_TYPE, UserAccountType } from '@pickles/shared/models/user.types';
import { Option } from '@pickles/shared/models/general.types';
import { ISignUp } from '@pickles/shared/utils/types';
import { CountriesResponse } from '@pickles/shared/services/api/response.types';
import { api } from '@pickles/shared/services/api';

import { IFormikErrors } from '../../util/types';

import { FieldHint, Label, RadioButton, RadioGroupContainer, RadioInput, RadioLabel, Select } from './style';
import Input from '../Shared/Input/Input';

interface EInvoicingFormProps extends IFormikErrors<ISignUp> {
  accType: UserAccountType;
  nationality: string | null;
  incorpCountry: string | null;
  tinId: string | null;
  sstId: string | null;
  brnId: string | null;
  handleChange: (e: ChangeEvent) => void;
}

export const EInvoicingForm: FC<EInvoicingFormProps> = ({
  accType,
  nationality,
  incorpCountry,
  tinId,
  sstId,
  brnId,
  handleChange,
  errors: {
    nationality: errorNationality,
    incorpCountry: errorIncorpCountry,
    tinId: errorTinId,
    sstId: errorSstId,
    brnId: errorBrnId,
  },
}) => {
  const [countryOptions, setCountryOptions] = useState<Option[]>([]);
  const { t } = useTranslation();

  const isBusinessAccount = accType === USER_ACCOUNT_TYPE.BUSINESS;
  const isMalaysiaBusiness = isBusinessAccount && incorpCountry && incorpCountry === 'MYS';
  
  const fetchCountryOptions = useCallback(async () => {
    //@ts-ignore
    const response: CountriesResponse = await api.getCountries();
    if (response.ok && response.data && response.data.length) {
      const countries = response.data.map((country: any) => ({
        value: country.code,
        label: country.value,
      }));
      setCountryOptions(countries);
    }
  }, []);

  useLayoutEffect(() => {
    fetchCountryOptions();
  }, [fetchCountryOptions]);

  const accTypeOptions = useMemo(
    () => [
      {
        value: USER_ACCOUNT_TYPE.INDIVIDUAL,
        label: t('fields:acc_type_individual'),
      },
      {
        value: USER_ACCOUNT_TYPE.BUSINESS,
        label: t('fields:acc_type_business'),
      },
    ],
    [t],
  );

  return (
    <div>
      <Label>{t('labels:reg_as')}</Label>
      <RadioGroup
        name={'accType'}
        options={accTypeOptions}
        onChange={handleChange}
        selectedValue={accType}
      />
      {isBusinessAccount ? (
        <>
          <Label>{t('labels:incorp_country')}</Label>
          <DropdownSelect
            name={'incorpCountry'}
            options={countryOptions}
            placeholder={t('fields:select_incorp_country')}
            onChange={handleChange}
            selectedValue={incorpCountry || undefined}
            error={errorIncorpCountry}
          />
        </>
      ) : (
        <>
          <Label>{t('labels:nationality')}</Label>
          <DropdownSelect
            name={'nationality'}
            options={countryOptions}
            placeholder={t('fields:select_nationality')}
            onChange={handleChange}
            selectedValue={nationality || undefined}
            error={errorNationality}
          />
        </>
      )}
      <Input
        id="tinId"
        name={'tinId'}
        onChange={handleChange}
        borderRadius={8}
        title={t('labels:tin_id')}
        placeholder={t('fields:tin_id')}
        type={'text'}
        width={'100%'}
        value={tinId ? tinId : ''}
        marginTop={32}
        marginBottom={0}
        paddingLeft={22}
        error={errorTinId}
      />
      {!isMalaysiaBusiness && <FieldHint>{`${t('infos:no_tin')} ${t('infos:enter_na')}`}</FieldHint>}
      {isBusinessAccount && (
        <>
          <Input
            id="sstId"
            name={'sstId'}
            onChange={handleChange}
            borderRadius={8}
            title={t('labels:sst_id')}
            placeholder={t('fields:sst_id')}
            type={'text'}
            width={'100%'}
            value={sstId ? sstId : ''}
            marginTop={32}
            marginBottom={0}
            paddingLeft={22}
            error={errorSstId}
          />
          {!isMalaysiaBusiness && <FieldHint>{`${t('infos:no_sst')} ${t('infos:enter_na')}`}</FieldHint>}
          <Input
            id="brnId"
            name={'brnId'}
            onChange={handleChange}
            borderRadius={8}
            title={isMalaysiaBusiness ? t('labels:brn_id_my') : t('labels:brn_id')}
            placeholder={t('fields:brn_id')}
            type={'text'}
            width={'100%'}
            value={brnId ? brnId : ''}
            marginTop={32}
            marginBottom={0}
            paddingLeft={22}
            error={errorBrnId}
          />
          {!isMalaysiaBusiness && <FieldHint>{`${t('infos:no_brn')} ${t('infos:enter_na')}`}</FieldHint>}
        </>
      )}
    </div>
  );
};

interface DropdownSelectProps {
  name: string;
  options: Option[];
  placeholder?: string;
  onChange: (e: ChangeEvent) => void;
  error?: string;
  selectedValue?: string;
}

const DropdownSelect: FC<DropdownSelectProps> = ({
  name,
  options,
  placeholder,
  onChange,
  error,
  selectedValue,
}) => {
  return (
    <>
      <Select
        name={name}
        id={name}
        onChange={onChange}
        error={!!error}
        isSelected={!!selectedValue}
        value={selectedValue || 'default'}
      >
        {placeholder && (
          <option key={'default'} value={'default'} disabled={!!selectedValue}>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {error && <FieldHint error={true}>{error}</FieldHint>}
    </>
  );
};

interface RadioGroupProps {
  name: string;
  options: Option[];
  onChange: (e: ChangeEvent) => void;
  selectedValue: string;
}

const RadioGroup: FC<RadioGroupProps> = ({ name, options, onChange, selectedValue}) => {
  return (
    <RadioGroupContainer>
      {options.map((option) => (
        <RadioButton key={option.value} isActive={selectedValue === option.value}>
          <RadioInput
            type={'radio'}
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
          />
          <RadioLabel>{option.label}</RadioLabel>
        </RadioButton>
      ))}
    </RadioGroupContainer>
  )
}